<template>
  <dashPage :title="$t('Users')">

    <template slot="default">
      <div class="mt-3">
        <router-view></router-view>
      </div>


    </template>
    <template #side>
      <h5 class="mt-6">{{ $t('Navigation') }}</h5>
      <v-list color="#6f93aa" dense class="pt-3">
        <v-list-item-group mandatory  v-model="navigation">
          <div v-for="(item,i) in menuItems" :key="i">
            <router-link :to="businessDashLink('users/' + item.value)" class="noUnderline">
              <v-list-item :value="item.value" color="white" >
                <v-list-item-icon > <v-icon>{{  item.icon  }}</v-icon> </v-list-item-icon>
                <v-list-item-title>{{  item.text  }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </div>

        </v-list-item-group>

      </v-list>
      <v-divider class="my-6"></v-divider>





    </template>

  </dashPage>
</template>

<script>

export default {
  name: "BusinessUsers",
  data() {
    return {
      navigation : ''
    }
  },
  computed : {
    locationName() {
      let locationName =  'users'
      let array = this.$route.path.split('/')
      if (array.length === 0) { return locationName }
       locationName = array[array.length-1]
       if (!locationName) { return  'users' }
      return locationName
    },
    menuItems() {
      return [
        { text: this.$t('AllUsers'), value: '',         icon: 'mdi-account'     },
        { text: this.$t('Managers'), value: 'managers', icon: 'mdi-account-cog' },
        { text: this.$t('Teachers'), value: 'teachers', icon: 'mdi-account-tie' },
        { text: this.$t('Students'), value: 'students', icon: 'mdi-account-details' } ,
        { text: this.$t('Clients'),  value: 'students', icon: 'mdi-account-details' } ,

        // { text: 'Абітурієнти', value: 'applicants', icon: 'mdi-account-multiple-plus',  },
      ]
    },
    filters() {
      switch (this.locationName) {
        case 'users' : return[
          { text: this.$t('ROLE_USER')      , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('ROLE_APPLICANT') , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('ROLE_STUDENT')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('ROLE_TEACHER')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('ROLE_MANAGER')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('ROLE_ADMIN')     , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
        ]
        case 'students' : return[
          { text: this.$t('Specialty')      , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('Degree') , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('StudyPlan')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('Semester')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
          { text: this.$t('EnrollmentWave')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },
        ]
        default : return [ { text: this.$t('ROLE_MANAGER')   , value: 'ROLE_ADMIN'  , icon:'mdi-school-outline' },]
      }

    },

  },
}
</script>

<style scoped>

</style>